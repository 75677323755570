<template>
  <m-modal
    :show="show"
    :title="title"
    @close="onClose"
    widthClasses="w-full lg:w-3/5"
    heightStyle="max-height: 85vh;"
  >
    <template v-slot:header_right>
      <span class="text-sm mr-2 hidden lg:inline"
        >A to add to notes | ESC to close</span
      >x
    </template>

    <!-- 85vh for entire modal, title:51px, footer:81px, y padding: 2x21px, roughly 174px -->
    <div
      data-recording-sensitive
      class="flex snippet-modal-content mx-7 flex-col lg:flex-row"
    >
      <div
        class="
          my-1
          flex-col
          contents-center
          flex-shrink
          lg:flex-shrink-0
          w-auto
          lg:w-64
          flex-grow
          lg:flex-grow-0
          overflow-y-auto overflow-x-hidden
        "
        :class="userIsEditing ? 'hidden lg:flex' : 'flex'"
        :style="isMobile ? 'flex-basis: 52%' : ''"
      >
        <div class="flex left-title">Calendar information</div>
        <div class="flex mb-1">
          <m-radio
            class="mb-1"
            filterName="event"
            :value="selected"
            :disabled="userIsEditing"
            @change="snippetGroupChange"
            segmentName="Snippet select"
            style="justify-content: flex-start"
          >
            <div class="w-auto lg:w-44 lg:truncate">
              <span title="Meeting description">Event description</span>
            </div>
          </m-radio>
        </div>

        <div class="left-title flex mt-3">Custom templates</div>
        <template v-if="customLoaded">
          <div class="flex mb-1" v-for="customId in customIds" :key="customId">
            <m-radio
              class="mb-1"
              :filterName="customId"
              :value="selected"
              :disabled="userIsEditing"
              @change="snippetGroupChange"
              segmentName="Snippet select"
              style="justify-content: flex-start"
            >
              <div class="w-auto lg:w-44 lg:truncate">
                <span :title="snippetsAll[customId].name">{{
                  snippetsAll[customId].name
                }}</span>
              </div>
            </m-radio>
          </div>
          <div
            class="flex text-grey3 cursor-pointer mt-2 mb-1"
            :class="userIsEditing ? 'opacity-50' : ''"
            v-if="customIds.length == 0 || accountPlan > 1"
          >
            <m-button
              :class="userIsEditing ? 'opacity-50' : ''"
              button-style="btn-tertiary"
              icon="➕"
              @click="onAddSnippet"
            >
              Create template
            </m-button>
          </div>
          <div
            v-if="customIds.length > 0 && accountPlan <= 1"
            class="text-grey2 text-sm opacity-75 mb-1"
          >
            Premium accounts have<br />
            <a-link
              href="https://help.meetric.app/en/articles/5507341-solo-premium"
              segmentName="Premium intent: solo, snippets"
              >unlimited templates</a-link
            >
          </div>
        </template>
        <template v-else>
          <div class="placeholder-wrapper">
            <m-placeholder class="placeholder"></m-placeholder>
          </div>
        </template>

        <div class="left-title flex mt-3">Agenda templates</div>
        <template v-if="templatesLoaded">
          <div
            class="flex mb-1"
            v-for="templateId in templateIds"
            :key="templateId"
          >
            <m-radio
              class="mb-1"
              :filterName="templateId"
              :value="selected"
              :disabled="userIsEditing"
              @change="snippetGroupChange"
              segmentName="Snippet select"
              style="justify-content: flex-start"
            >
              <div class="w-auto lg:w-44 lg:truncate">
                <span :title="snippetsAll[templateId].name">{{
                  snippetsAll[templateId].name
                }}</span>
              </div>
            </m-radio>
          </div>
        </template>
        <template v-else>
          <div class="placeholder-wrapper">
            <m-placeholder class="placeholder"></m-placeholder>
          </div>
          <div class="placeholder-wrapper">
            <m-placeholder class="placeholder"></m-placeholder>
          </div>
          <div class="placeholder-wrapper">
            <m-placeholder class="placeholder"></m-placeholder>
          </div>
          <div class="placeholder-wrapper">
            <m-placeholder class="placeholder"></m-placeholder>
          </div>
        </template>

        <div class="left-title flex mt-3">Icebreaker templates</div>
        <template v-if="icebreakersLoaded">
          <div
            class="flex mb-1"
            v-for="icebreakerId in icebreakerIds"
            :key="icebreakerId"
          >
            <m-radio
              class="mb-1"
              :filterName="icebreakerId"
              :value="selected"
              :disabled="userIsEditing"
              @change="snippetGroupChange"
              segmentName="Icebreaker select"
              style="justify-content: flex-start"
            >
              <div class="w-auto lg:w-44 lg:truncate">
                <span :title="snippetsAll[icebreakerId].name">{{
                  snippetsAll[icebreakerId].name
                }}</span>
              </div>
            </m-radio>
          </div>
        </template>
        <template v-else>
          <div class="placeholder-wrapper">
            <m-placeholder class="placeholder"></m-placeholder>
          </div>
          <div class="placeholder-wrapper">
            <m-placeholder class="placeholder"></m-placeholder>
          </div>
          <div class="placeholder-wrapper">
            <m-placeholder class="placeholder"></m-placeholder>
          </div>
          <div class="placeholder-wrapper">
            <m-placeholder class="placeholder"></m-placeholder>
          </div>
        </template>
      </div>
      <div
        v-if="!userIsEditing"
        class="flex lg:hidden items-center"
        style="height: 2.6rem"
      >
        <hr class="w-full border-grey6" />
      </div>
      <!-- Content column -->
      <div v-if="userIsEditing" class="flex flex-col flex-grow">
        <div
          class="
            flex-grow
            text-grey1
            relative
            my-1
            p-2
            ml-1
            border
            overflow-y-auto
            rounded-lg
          "
        >
          <snippet-editor
            :uuid="selected"
            :content="getContentSafe()"
            :name="getNameSafe"
            :getNote="getNote"
            @noteHTML="saveCustomSnippet"
          ></snippet-editor>
        </div>
        <div class="h-8 flex m-1 flex-grow-0 flex-shrink-0 justify-left">
          <m-button
            button-style="btn-tertiary"
            :pending="userIsSaving"
            icon="🖊️"
            class="mr-2"
            @click="onCustomSave"
          >
            Save
          </m-button>
          <m-button
            button-style="btn-tertiary"
            @click="onCancelClick"
            icon="✖️"
          >
            Cancel
          </m-button>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col flex-grow overflow-y-auto"
        :style="isMobile ? 'flex-basis: 48%' : ''"
      >
        <div
          class="
            flex flex-col flex-grow
            relative
            ml-1
            my-1
            overflow-y-auto
            border
            rounded-lg
          "
        >
          <div class="flex flex-grow flex-shrink m-4">
            <div v-if="customSelected">
              <div
                v-if="snippetsAll[selected].content"
                data-recording-sensitive
                v-html="isMobile ? getContentWithHeader() : getContentSafe()"
                class="text-grey1 break-words templates-content"
              ></div>
            </div>
            <div
              v-else-if="snippetsAll[selected].content"
              data-recording-sensitive
              v-html="isMobile ? getContentWithHeader() : getContentSafe()"
              class="text-grey1 break-words templates-content"
            ></div>
            <div
              v-else
              class="
                text-grey2
                break-words
                pr-3
                mb-2
                max-h-64
                lg:max-h-80
                templates-content
                mx-auto
                italic
              "
              style="overflow: overlay"
            >
              {{ noContentMessage[snippetsAll[selected].type] || '' }}
            </div>
          </div>
        </div>
        <div
          v-show="customSelected"
          class="h-8 flex m-1 flex-grow-0 flex-shrink-0 justify-left"
        >
          <m-button
            button-style="btn-tertiary"
            @click="userIsEditing = !userIsEditing"
            icon="✍️"
            class="mr-2"
          >
            Edit
          </m-button>
          <m-button
            button-style="btn-tertiary"
            class=""
            :pending="userIsDeleting"
            @click="onCustomDelete"
            icon="✖️"
          >
            Delete
          </m-button>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <template v-slot:bottom_modal>
      <!-- Left element -->
      <div class="flex">
        <div class="flex flex-col text-base justify-center">
          <a-link
            href="https://help.meetric.app/en/articles/4798304-snippets-agendas"
            segmentName="How templates work"
            :segmentProps="{ from: 'Templates modal' }"
            >How templates work</a-link
          >
        </div>
      </div>
      <!-- Right element -->
      <div class="flex justify-between" data-tooltip="addToNotes">
        <m-button
          :class="userIsEditing ? 'opacity-50' : ''"
          button-style="btn-primary"
          @click="addToNotes"
          icon="📝"
          :disabled="userIsEditing"
        >
          Add to notes
        </m-button>
        <hover-tool-tip
          label="You can not add to notes while editing."
          selector='[data-tooltip="addToNotes"]'
          placement="top"
          :disableTooltip="!userIsEditing"
        ></hover-tool-tip>
      </div>
    </template>
  </m-modal>
</template>

<script>
import HoverToolTip from '@/components/UI/HoverToolTip';
import MButton from '@/components/UI/MButton';
import MRadio from '@/components/UI/MRadio';
import MModal from '@/components/UI/MModal';
import SnippetEditor from '@/components/Editor2Snippet';
import ALink from '@/components/UI/ALink.vue';
import MPlaceholder from '@/components/UI/MPlaceholder';
import {
  getAgendaTemplates,
  getCustomSnippets,
  setCustomSnippet,
  deleteCustomSnippet,
  getIcebreakers,
} from '@/api/Cherry';

export default {
  name: 'SnippetModal',
  components: {
    MButton,
    MRadio,
    MModal,
    MPlaceholder,
    SnippetEditor,
    HoverToolTip,
    ALink,
  },
  props: {
    html: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    autoInsertCounter: {
      type: Number,
      required: false,
      default: 0,
    },
    autoInsertUuid: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      templatesLoaded: false,
      icebreakersLoaded: false,
      customLoaded: false,
      templateIds: [],
      customIds: [],
      icebreakerIds: [],
      snippetsAll: {
        event: {
          type: 'event',
          content: '',
          section: 'Calendar information',
          name: 'Event description',
        },
      },
      noContentMessage: {
        event: `Oops – no agenda in the event description. Why not try an agenda template?`,
        template: `tem`,
        custom: `cust`,
        icebreaker: `icebreaker`,
      },
      selected: 'event',
      customSelected: false,
      userIsEditing: false,
      userIsDeleting: false,
      userIsSaving: false,
      getNote: 0, // helper to get html from editor when incremented
    };
  },

  watch: {
    show: {
      handler(val) {
        if (val) this.addListener();
        else this.removeListener();
      },
    },
    autoInsertCounter: {
      // counter used to insert something to notes
      // we can not watch ID only as users may insert same ID repeatedly
      handler() {
        this.selected = this.autoInsertUuid;
        this.customSelected = false;
        this.addToNotes();
      },
    },
  },
  mounted() {
    // event description
    this.snippetsAll['event'].content = this.html;
    this.snippetGroupChange('event');
    this.loadData();
  },
  beforeDestroy() {
    this.removeListener();
  },
  methods: {
    addListener() {
      window.addEventListener('keydown', this.shortcut);
    },
    removeListener() {
      window.removeEventListener('keydown', this.shortcut);
    },
    onCancelClick() {
      if (this.selected == '') this.selected = 'event';
      this.userIsEditing = !this.userIsEditing;
      this.snippetGroupChange(this.selected);
    },
    onAddSnippet() {
      if (this.userIsEditing) return;

      this.selected = '';
      this.userIsEditing = true;
      this.customSelected = true;
    },
    onCustomDelete() {
      if (!this.customSelected) return;

      this.userIsDeleting = true;

      const uuid = this.selected;
      deleteCustomSnippet(uuid).then(() => {
        this.userIsDeleting = false;
        this.customIds = this.customIds.filter((id) => id != uuid);
        delete this.snippetsAll[uuid];
        this.snippetGroupChange('event');
      });
    },
    onCustomEdit() {
      if (!this.customSelected) return;
    },
    onCustomSave() {
      if (!this.customSelected) return;

      this.getNote++; // increment so editor can emit content
    },

    loadData() {
      if (!this.templatesLoaded) this.loadTemplates();
      if (!this.customLoaded) this.loadCustomSnippet();
      if (!this.icebreakersLoaded) this.loadIcebreakers();
    },

    snippetGroupChange(snippetId) {
      this.selected = snippetId;
      this.customSelected = this.snippetsAll[snippetId].type == 'custom';
    },
    loadIcebreakers() {
      getIcebreakers().then((data) => {
        const icebreakers = data.sort((a, b) => a.name.localeCompare(b.name));
        icebreakers.forEach((item) => {
          this.icebreakerIds.push(item.uuid);
          this.snippetsAll[item.uuid] = {
            type: 'icebreaker',
            name: item.name,
            // remove new lines from icebreakers as they would create extra line in editor
            content: item.content.replace(/\n[ ]*/g, '') || '',
            section: 'Icebreaker templates',
          };
        });
      });

      this.icebreakersLoaded = true;
    },
    loadTemplates() {
      getAgendaTemplates().then((data) => {
        const templates = data.sort((a, b) =>
          (a?.sortName || a.name).localeCompare(b?.sortName || b.name)
        );
        templates.forEach((item) => {
          this.templateIds.push(item.uuid);
          this.snippetsAll[item.uuid] = {
            type: 'template',
            name: item.name,
            content: item.content || '',
            section: 'Agenda templates',
          };
        });

        this.templatesLoaded = true;
      });
    },
    loadCustomSnippet() {
      getCustomSnippets().then((data) => {
        const sorted = data.sort((a, b) => a.name.localeCompare(b.name));
        sorted.forEach((item) => {
          this.customIds.push(item.uuid);
          this.snippetsAll[item.uuid] = {
            type: 'custom',
            name: item.name,
            content: item.content || '',
            section: 'Custom templates',
          };
        });
        this.customLoaded = true;
      });
    },
    saveCustomSnippet(event) {
      this.userIsSaving = true;
      // event= {uuid, name, content}

      setCustomSnippet(event).then((r) => {
        if (r?.uuid && r?.name) {
          if (this.snippetsAll[r.uuid]) {
            // existing
            const item = {
              type: 'custom',
              name: r.name,
              content: r.content || '',
              section: 'Custom templates',
            };

            this.$set(this.snippetsAll, r.uuid, item);
          } else {
            // new

            this.snippetsAll[r.uuid] = {
              type: 'custom',
              name: r.name,
              content: r.content || '',
              section: 'Custom templates',
            };
            this.customIds.push(r.uuid);
            this.selected = r.uuid;
          }
        }

        this.userIsSaving = false;
        this.userIsEditing = false;
      });
    },
    onClose() {
      //for testing
      // this.customLoaded = false;
      // this.templatesLoaded = false;
      // this.templateIds = [];
      // this.customIds = [];

      this.$emit('close');
    },

    shortcut(e) {
      if (this.userIsEditing || this.isMobile) return;

      if (e.key == 'a') {
        this.addToNotes();
      }
    },
    addToNotes() {
      let agendaName = '';
      let text = '';
      if (this.selected == 'event') {
        agendaName = 'Google agenda';
        text = this.bulletsToAgendaItem(this.getContentSafe());
      } else {
        agendaName = this.snippetsAll[this.selected].name;
        text = this.uuidRemover(this.getContentSafe());
      }
      // console.log(text);
      this.$emit('addagendatonotes', {
        html: text,
        name: agendaName,
        custom: this.customSelected,
      });
      this.$emit('close');
    },
    bulletsToAgendaItem(html) {
      let doc = new DOMParser().parseFromString(html, 'text/html');
      const listItems = doc.querySelectorAll('li');
      for (const li of listItems) {
        if (
          li.firstChild.nodeName === 'UL' ||
          li.firstChild.nodeName === 'OL'
        ) {
          continue;
        }

        const div = document.createElement('div');
        div.dataset.type = 'agenda_item';
        if (li.firstChild.nodeType == Node.TEXT_NODE) {
          div.innerText = li.firstChild.textContent;
        } else {
          div.innerHTML = li.firstChild.outerHTML;
        }
        li.removeChild(li.firstChild);
        li.insertAdjacentElement('afterbegin', div);
      }

      return doc.body.innerHTML;
    },
    uuidRemover(html) {
      return html.replace(/data-uuid="[^"]*"/g, '');
    },
    getContentSafe() {
      return this.snippetsAll[this.selected]?.content || '';
    },
    getContentWithHeader() {
      if (!this.snippetsAll[this.selected]?.content) return '';

      return this.getHeader() + this.snippetsAll[this.selected].content;
    },
    getHeader() {
      return `<p><b>${this.snippetsAll[this.selected].section} - ${
        this.snippetsAll[this.selected].name || ''
      }</b></p><p></p>`;
    },
  },
  computed: {
    isMobile() {
      return this.$store?.getters['isMobile'];
    },
    accountPlan() {
      return this.$store?.getters['plan'];
    },

    getNameSafe() {
      return this.snippetsAll[this.selected]?.name || '';
    },
  },
};
</script>
<style scoped>
.snippet-modal-content {
  /* 80vh for entire modal, title:51px, footer:77px, y padding: 2x21px, roughly 170px  */
  height: calc(85vh - 171px);
}
@screen lg {
  .snippet-modal-content {
    /* 80vh for entire modal, title:51px, footer:81px, y padding: 2x21px, roughly 175px  */
    height: calc(85vh - 175px);
  }
}

.left-title {
  @apply text-grey1 font-medium mb-2;
}
.placeholder-wrapper {
  @apply flex text-base mb-2;
  line-height: 1.5;
}
.placeholder-wrapper::before {
  content: '\200b';
}
.placeholder {
  @apply rounded-lg my-auto w-5/6;
  height: 1rem;
}

#template-select >>> select {
  width: 150px;
}

.agenda-options span {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.agenda-options span.icon-g {
  background-image: url('~@/assets/logos/g-logo.webp'),
    url('~@/assets/logos/g-logo.png');
}
.agenda-options span.icon-m {
  background-size: 99%;
  margin-right: -0.5rem;
  background-image: url('~@/assets/logos/meetric-logo.svg');
  filter: invert(52%) sepia(82%) saturate(2237%) hue-rotate(150deg)
    brightness(87%) contrast(104%);
}

.agenda-options div.inactive {
  opacity: 0.6;
  filter: grayscale(100%);
}

.templates-content >>> h1 {
  @apply text-2xl font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1.3rem;
  margin-bottom: 9px;
}
.templates-content >>> h2 {
  @apply text-xl font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1rem;
  margin-bottom: 6px;
}
.templates-content >>> h3 {
  @apply text-lg font-medium;
  letter-spacing: 0.02rem;
  margin-top: 1rem;
  margin-bottom: 9px;
}
.templates-content >>> h1:first-child,
.templates-content >>> h2:first-child,
.templates-content >>> h3:first-child {
  margin-top: 0;
}
.templates-content >>> a {
  @apply underline cursor-pointer;
}
.templates-content >>> p {
  min-height: 10px;
}
.templates-content >>> p:empty {
  min-height: 22px;
}
</style>

<template>
  <div>
    <m-options-toggle
      :value="value"
      :readonly="readonly"
      :widthInRem="11"
      :heightInRem="2"
      class="text-sm font-medium"
      segmentName="Private notes toggle"
      v-on:input="onInput($event)"
    >
      <template v-slot:leftFalse>
        <div data-tooltip="private-toggle-left">
          <div class="inline-block" style="vertical-align: text-bottom">
            <svg
              class="shared mr-2"
              :class="{ 'opacity-75': value, highlight: hasShared }"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="#b5b5b5"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="5" y="3" width="14" height="18" rx="2" />
              <line x1="9" y1="7" x2="15" y2="7" />
              <line x1="9" y1="11" x2="15" y2="11" />
              <line x1="9" y1="15" x2="13" y2="15" />
            </svg>
          </div>
          <span :class="sharedClass" style="margin-right: 2px">{{
            isMeetingPublicRead ? 'Public' : 'Shared'
          }}</span>
        </div>

        <hover-tool-tip
          :label="hoverRight"
          selector='[data-tooltip="private-toggle-left"]'
          :placement="isExtension ? 'top' : 'bottom'"
          :offset="12"
          url="https://help.meetric.app/en/articles/4798217-who-can-see-my-notes"
        ></hover-tool-tip>
      </template>
      <template v-slot:rightTrue>
        <div data-tooltip="private-toggle-right">
          <div class="inline-block" style="vertical-align: text-bottom">
            <svg
              class="private mr-2"
              :class="{ 'opacity-75': !value, highlight: hasPrivate }"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="#b5b5b5"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="5" y="3" width="14" height="18" rx="2" />
              <line x1="9" y1="7" x2="15" y2="7" />
              <line x1="9" y1="11" x2="15" y2="11" />
              <line x1="9" y1="15" x2="13" y2="15" />
            </svg>
          </div>
          <span :class="privateClass" style="margin-right: 2px">Private</span>
        </div>
        <hover-tool-tip
          label="This side is for your eyes only"
          selector='[data-tooltip="private-toggle-right"]'
          :placement="isExtension ? 'top' : 'bottom'"
          :offset="12"
          url="https://help.meetric.app/en/articles/4798217-who-can-see-my-notes"
        ></hover-tool-tip>
      </template>
    </m-options-toggle>
  </div>
</template>

<script>
import MOptionsToggle from '../MOptionsToggle.vue';
import HoverToolTip from '@/components/UI/HoverToolTip';

export default {
  name: 'MSharedPrivateToggle',
  components: {
    MOptionsToggle,
    HoverToolTip,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasShared: {
      type: Boolean,
      default: false,
    },
    hasPrivate: {
      type: Boolean,
      default: false,
    },
    isMeetingPublicRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    isExtension() {
      return !!this.$store?.getters['isExtension'];
    },
    hoverRight() {
      if (this.isMeetingPublicRead)
        return 'This side is for anyone with the link';

      return 'This side is for all signed-in meeting guests';
    },
    sharedClass() {
      if (this.hasShared) return 'text-base2';
      else return 'text-grey3 dark:text-grey5';
    },
    privateClass() {
      if (this.hasPrivate) return 'text-base3';
      else return 'text-grey3 dark:text-grey5';
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped>
svg.shared {
  @apply stroke-current text-grey3 dark:text-grey5;
}
svg.shared.highlight {
  @apply stroke-current text-base2;
}

svg.private {
  @apply stroke-current text-grey3 dark:text-grey5;
}
svg.private.highlight {
  @apply stroke-current text-base3;
}
</style>
